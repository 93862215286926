
import React, {useState, useEffect} from "react"
import PropTypes from "prop-types"
import BlogsDetailContainer from "./pages/BlogsDetail/Container";import { caseStudyDetailData } from "../data/data";
import { useParams } from "react-router-dom";
import CaseStudiesDetailContainer from "./pages/CaseStudyDetail/Container";


const StacticCaseStudyDetailPage = (props) => {
  console.log(props)
  console.log(props.case_study_detail_id)
  const [blogs, setBlogs] = useState([])
    // const { blogId } = useParams()
    const detailData = caseStudyDetailData && caseStudyDetailData?.find((b) => {
      console.log(b)
        
        return props.case_study_detail_id === b.id
    })
    // setBlogs(blogData)
    // useEffect(() => {
    //   setBlogs(blogData)
    // }, [])
    
    return (
      <>  
        <CaseStudiesDetailContainer detailData={detailData} />
        {/* <Investors /> */}
      </>
    );
  
}

export default StacticCaseStudyDetailPage
