import React, { useEffect, useState } from 'react'
// import { HomeBannerData, HomeBannerModalVideoData } from '../../../data/data';
import { HomeBannerData } from '../../../data/data';
import Slider from 'react-slick';
// import Modal from 'react-bootstrap/Modal';

// const NewBannerMob = ({videoEnded,handleClickPlay,showElement}) => {
const NewBannerMob = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 4000,
        initialSlide: 0,
    };
 
    // const [showVideo, setVideoShow] = useState(false);

    // const handleVideoClose = () => setVideoShow(false);
    // const handleVideoShow = () => setVideoShow(true);
    // useEffect(() => {

    // }, [])
    return (
        <>
            <section className='homeBanner homeBannerLatest eleContainer'>
            {/* {!showElement ? (
                <div className="thumbnail-wrapper" onClick={handleClickPlay}>
                    <i className='bi bi-play-fill'></i>
                </div>
            )
                : null} */}
                <div className='container'>

                    <h1>The Most Democratic Influencer<br /> Marketing Platform of the World. </h1>
                    <Slider {...settings}>
                        <div className='punchline-slide' >
                            <strong className='pb-2 pb-xxl-3 d-block'>For Brands & Advertisers</strong>
                            {HomeBannerData.HomeBannerSlider.map(bannerData => {

                                return bannerData.brands?.map(brandData => {
                                    return <div key={brandData.id} className='banner-caption'>
                                        <div className='banner-caption-right'>
                                            <div className='banner-caption-center'>
                                                <span className='banner-caption-arrow-sec'>
                                                    <span className='banner-caption-arrow'>
                                                    </span>
                                                    <span className='banner-caption-arrow1'>
                                                    </span>
                                                </span>
                                                <strong>
                                                    {brandData.bannerCaptionCenterTitle}
                                                </strong>
                                            </div>
                                            <div className='banner-caption-bottom'>
                                                <span className='banner-caption-bottom-desc-mob'>
                                                    {brandData.bannerCaptionBottomTitle}&nbsp;
                                                </span>
                                                <a href="/our_business_models">{brandData.bannerCaptionBottomAnchor}</a>
                                            </div>
                                        </div>
                                    </div>
                                })

                            })}
                            <div className='d-flex mt-2 banner-bottom-btn '>
                                <a href="/request_demo" className='btn btn-outline-black fs-16'>Request a demo</a>
                                {/* <span className='video-play ml-auto d-block d-md-none' onClick={handleVideoShow}>
                                    <i className='bi bi-play-circle'></i>
                                    Watch Video
                                </span> */}
                            </div>
                        </div>

                        <div className="punchline-slide" >
                            <strong className='pb-2 pb-xxl-3 d-block'>For Influencers & Talent Partners</strong>
                            {HomeBannerData.HomeBannerSlider.map(bannerData => {

                                return bannerData.influencers?.map(brandData => {
                                    return <div key={brandData.id} className='banner-caption'>
                                        <div className='banner-caption-right'>
                                            <div className='banner-caption-center'>
                                                <span className='banner-caption-arrow-sec'>
                                                    <span className='banner-caption-arrow'>
                                                    </span>
                                                    <span className='banner-caption-arrow1'>
                                                    </span>
                                                </span>
                                                <strong>
                                                    {brandData.bannerCaptionCenterTitle}
                                                </strong>
                                            </div>
                                            <div className='banner-caption-bottom'>
                                                <span className='banner-caption-bottom-desc-mob'>
                                                    {brandData.bannerCaptionBottomTitle}&nbsp;
                                                </span>
                                                <a href="/our_business_models">{brandData.bannerCaptionBottomAnchor}</a>
                                            </div>
                                        </div>
                                    </div>
                                })

                            })}
                            <div className='banner-bottom-btn d-flex mt-2 mb-3'>
                                <a href="/user_signup" className='btn btn-outline-black fs-16'>Sign Up Now</a>
                                {/* <span className='video-play ml-auto  d-block d-md-none' onClick={handleVideoShow}>
                                    <i className='bi bi-play-circle'></i>
                                    Watch Video
                                </span> */}
                            </div>
                        </div>
                    </Slider>

                </div>

            </section>
            {/* <Modal className='bgVideoModal' show={showVideo} onHide={handleVideoClose} centered>
                <Modal.Header closeButton="true">
                </Modal.Header>
                <Modal.Body>
                    <video playsInline autoPlay controls poster="polina.jpg" id="bgvid">
                        <source src={HomeBannerModalVideoData.videoURL} type="video/mp4" />
                    </video>
                </Modal.Body>
            </Modal> */}
        </>
    )
}

export default NewBannerMob