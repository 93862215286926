import React from 'react';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';


const OurBusinessModalBrands = () => {

    const addClass = document.querySelector('body');
    console.log(addClass)
    addClass.classList.add("common-bg-page")
    addClass.classList.add("page-business-model")


    return (
        <>
            <div className="homeBanner homeBannerLatest inner news ">
                <div className="container">
                    <div className="banner-content">
                        <h1 className="banner-innerpage-heading">Our Business Models</h1>
                    </div>
                </div>
            </div>
            
            <section className='sec-accordion sec-our-business width-85'>
                {/* <div className="banner-innerpage">
                    <div className='banner-content'>
                        <h1 className='banner-innerpage-heading'>Our Business Models</h1>
                    </div>
                </div> */}
                <div className='our-business-models'>
                    <div className='container'>
                        <div className='our-business-models-content'>
                            <h3>
                                <span>For Brands and Agencies</span>
                            </h3>
                            <Tab.Container className='our-business-models-tab-sec' id="left-tabs-example" defaultActiveKey="first">
                                <Nav variant="pills">
                                    <Nav.Item>
                                    <Nav.Link eventKey="first" className='business-modal-btn p-none'>Self - served (FOC) model</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                    <Nav.Link eventKey="second" className='business-modal-btn p-none'>Managed Services</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                                <Tab.Content >
                                    <Tab.Pane eventKey="first">
                                        <p>
                                        The platform provides one free end-to-end campaign execution per month upon sign-up. You can choose any one from barter, paid, paid + barter, or CPV campaigns. With the free campaign, you can post a brief, discover influencers, negotiate proposals, receive creative content and give feedback, direct payment to influencers and view campaign analytics. 
                                        </p>

                                        <strong>What you get for 30 days:</strong>
                                        <p>
                                        <ul className='margin-left-25'>
                                        <li>One free campaign posting per month.</li>
                                        <li>25 searches using filters, names, or usernames.</li>
                                        <li>100 influencer profile views (Instagram and YouTube included).</li>
                                        <li>Creation of 4 lists with 25 profiles each.</li>
                                        </ul>
                                        </p>
                                        <span className='font14'>Terms & conditions</span>
                                        <ol className='margin-left-25 font14'>
                                        <li>FOC window of 30 days starts from the day you signup.</li>
                                        <li>FOC campaign extension or any additional campaign can be activated by paying ₹1000 each in the same month or by taking a premium <a href ="/pricing">subscription plan</a>.</li>
                                        <li>All access related to FOC campaign shall be blocked on the FOC completion window.</li>
                                        </ol>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="second">
                                    <strong>Commission Model </strong>
                                    <p>A dedicated team of campaign planners and executioners operates on a fixed commission model, charging 10% of the brand’s campaign budget/layout. This model is ideal for substantial brands and large-scale agencies.</p>
                                    <strong>Project Model</strong>
                                    <p>A one-off campaign where the team defines the scope of work and executes it as a single project cost.</p>
                                    <strong>Retainer Model</strong>
                                    <p>
                                    Designed for brands seeking continuous engagement with influencers on a monthly or long-term basis. The team outlines the scope of work, allocates resources, and operates on a monthly retainer with fixed deliverables. 
                                    </p>
                                    <p>Minimum billing to avail managed services is 1 Lac or above + taxes per month (applies to commission and project model).</p>
                                    </Tab.Pane>
                                </Tab.Content>
                            </Tab.Container>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default OurBusinessModalBrands