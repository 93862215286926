//Founder Images
import kunalImg from "../../../assets/images/FoundersImages/kunal.png";
import sagarImg from "../../../assets/images/FoundersImages/sagar.png";
import anshaiImg from "../../../assets/images/FoundersImages/anshai.png";
import kunalImgMob from "../../../assets/images/FoundersImages/kunal-kishore-sinha.png";
import sagarImgMob from "../../../assets/images/FoundersImages/sagar-pushp.png";
import anshaiImgMob from "../../../assets/images/FoundersImages/anshai-lal.png";

//Founder's Interest
import Beach from "../../../assets/images/Founders-Interests/Beach.svg";
import Badminton from "../../../assets/images/Founders-Interests/BM.svg";
import Socialising from "../../../assets/images/Founders-Interests/Glass.svg";
import Chess from "../../../assets/images/Founders-Interests/Chess.svg";
import Chicken from "../../../assets/images/Founders-Interests/Chicken.svg";
import Army from "../../../assets/images/Founders-Interests/Cap.svg";
import WeightLifting from "../../../assets/images/Founders-Interests/Weight.svg";
import Sweets from "../../../assets/images/Founders-Interests/Sweets.svg";
import Movies from "../../../assets/images/Founders-Interests/Movies.svg";
import Cricket from "../../../assets/images/Founders-Interests/Cricket.svg";

const FoundersData = [
  {
    id: "1",
    founderImg: kunalImg,
    founderImgMob: kunalImgMob,
    founderName: "KUNAL KISHORE SINHA",
    founderDesignation: "COO",
    founderInfoPara1:
      "A self-motivated leader with a zest of entrepreneurship that drives the organization to excel in serving the client.",
    founderInfoPara2:
      "Kunal’s entrepreneurial career began with the formation of Value360 Communications in 2007. He has led PR campaigns for many international brands like Paytm, MG Motors, Droom,Lufthansa Airlines, UrbanCompany, Zoomcar, Cleartrip.com, Hoegaarden, Budweiser among others over a decade in the  industry.",
    interest1: Beach,
    interest2: Badminton,
    interest3: Socialising,
    interest4: Chess,
  },
  {
    id: "2", 
    founderImg: sagarImg,
    founderImgMob: sagarImgMob,
    founderName: "SAGAR PUSHP",
    founderDesignation: "CEO",
    founderInfoPara1:
      "Sagar has managed Samsung's and Unilever's multi-million dollar digital media mandates. He has a strong background in performance marketing and ad-tech while leading IPG Mediabrands programmatic division and overseeing major clients like Amazon, J&J, and BMW, to mention a few. He was initially enlisted in the Indian Army as a soldier, retired as a captain, and worked in sales for Airtel and Reliance Communications.",
    interest1: Chicken,
    interest2: Badminton,
    interest3: Army,
    interest4: WeightLifting,
  },
  {
    id: "3",
    founderImg: anshaiImg,
    founderImgMob: anshaiImgMob,
    founderName: "ANSHAI LAL",
    founderDesignation: "CCO",
    founderInfoPara1:
      "A seasoned professional from media, advertising and entertainment industry. Anshai has spent more than 15 years in Indian Film Industry and directed movie Phillauri starrer Anushka Sharma and Diljit. He is also the Director and creative producer at Cleanslate films.",
    interest1: Sweets,
    interest2: Movies,
    interest3: Badminton,
    interest4: Cricket,
  },
];

export default FoundersData;
