import React, { useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom';
import { caseStudyData } from "../../../data/data";


const CaseStudyContainer = (props) => {
    const addClass = document.querySelector('body');
    addClass.classList.add("common-bg-page")
    const [caseStudyCategory, setcaseStudyCategory] = useState('All');

    const handleTabClick = (category) => {
        setcaseStudyCategory(category);
    };

    const filteredCategories = caseStudyCategory === 'All' ? caseStudyData.caseStudyList : caseStudyData.caseStudyList.filter(caseStudy => caseStudy.category === caseStudyCategory);


    return (
        <>
            <div className="homeBanner homeBannerLatest inner news">
                <div className="container">
                    <div className="banner-content">
                        <h1 className="banner-innerpage-heading">Case Studies</h1>
                    </div>
                </div>
            </div>

            <div className="banner-case-study">
                <div className='d-block d-lg-none mb-4 w-100 px-3'>
                    <select className='form-control' value={caseStudyCategory} onChange={(e) => handleTabClick(e.target.value)}>
                        <option value="All">All</option>
                        <option value="Automobile">Automobile</option>
                        <option value="Beauty & Personal Care">Beauty & Personal Care</option>
                        <option value="Fashion">Fashion</option>
                        <option value="FMCG">FMCG</option>
                        <option value="Lifestyle & Travel">Lifestyle & Travel</option>
                        <option value="Tech & Fin">Technology & Finance</option>
                    </select>
                </div>

                <div className="case-study-category-tab-sec d-none d-lg-flex">
                    <button className={`${caseStudyCategory === 'All' ? "case-study-category-tab active" : "case-study-category-tab"}`} onClick={() => handleTabClick('All')}>All</button>
                    <button className={`${caseStudyCategory === 'Automobile' ? "case-study-category-tab active" : "case-study-category-tab"}`} onClick={() => handleTabClick('Automobile')}>Automobile</button>
                    <button className={`${caseStudyCategory === 'Beauty & Personal Care' ? "case-study-category-tab active" : "case-study-category-tab"}`} onClick={() => handleTabClick('Beauty & Personal Care')}>Beauty & Personal Care</button>
                    <button className={`${caseStudyCategory === 'Fashion' ? "case-study-category-tab active" : "case-study-category-tab"}`} onClick={() => handleTabClick('Fashion')}>Fashion</button>
                    <button className={`${caseStudyCategory === 'FMCG' ? "case-study-category-tab active" : "case-study-category-tab"}`} onClick={() => handleTabClick('FMCG')}>FMCG</button>
                    <button className={`${caseStudyCategory === 'Lifestyle & Travel' ? "case-study-category-tab active" : "case-study-category-tab"}`} onClick={() => handleTabClick('Lifestyle & Travel')}>Lifestyle & Travel</button>
                    <button className={`${caseStudyCategory === 'Tech & Fin' ? "case-study-category-tab active" : "case-study-category-tab"}`} onClick={() => handleTabClick('Tech & Fin')}>Technology & Finance</button>
                </div>
                <ul className='case-study-list'>
                    {filteredCategories.slice().reverse().map(caseStudy => {
                        return <li key={caseStudy.id}>
                            <a className ="case-studies-detail" href={`/case_studies/case_study_detail?case_study_detail_id=${caseStudy.id}`}>
                                <img src={caseStudy.img} alt={caseStudy.title} />
                                <div className='case-study-detail'>
                                    <strong className='font14'>
                                        {caseStudy.title}
                                    </strong>
                                    <span className='font14 pb-2'>{caseStudy.hashTag}</span>
                                    <span className='hover-underline text-right font12 mb-1'>
                                        <a href={`/case_studies/case_study_detail?case_study_detail_id=${caseStudy.id}`}>Know More</a>
                                    </span>

                                </div>
                                
                            </a>
                        </li>
                    })}

                </ul>
            </div>

        </>

    )
}

export default CaseStudyContainer