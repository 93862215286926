import React, { useState, useEffect } from "react";
const CaseStudiesDetailContainer = (props) => {
  console.log(props)
  return (
    <>
      <div className="banner-case-study-details">
        <div className="brandBackground">
          <div className="brand-details">
            <div className="brand-name">
              <span> {props.detailData.brandName}</span>
            </div>
            <div className="brand-hashtag">
              <span className={props.detailData.brandHashtag.length > 18 ? "fontResponsive" : ""}>{props.detailData.brandHashtag}</span>
            </div>
          </div>
          {props.detailData.brandInfo?.map((y) => (
            <div className="brand-info">
              {y.brandInfoTitle &&
                <div className="title">
                  <span class="banner-caption-arrow-sec">
                    <span class="banner-caption-arrow"></span>
                    <span class="banner-caption-arrow1"></span>
                  </span>

                  {y.brandInfoTitle}
                </div>
              }
              <div className="description">
                <ul>
                  {y.brandInfoDescription1 ? <li>{y.brandInfoDescription1}</li> : null}
                  {y.brandInfoDescription2 ? <li>{y.brandInfoDescription2}</li> : null}
                  {y.brandInfoDescription3 ? <li>{y.brandInfoDescription3}</li> : null}
                  {y.brandInfoDescription4 ? <li>{y.brandInfoDescription4}</li> : null}
                  {y.brandInfoDescription5 ? <li>{y.brandInfoDescription5}</li> : null}
                </ul>
              </div>
            </div>
          ))}
        </div>
        {props.detailData.differentDescription && props.detailData.differentDescription ?
          <div className="brand-different">


            <div className="brand-different-title">
              <span class="banner-caption-arrow-sec">
                <span className="banner-caption-arrow"></span>
                <span className="banner-caption-arrow1"></span>
              </span>
              <span className="brand-result-text">

                {props.detailData.differentTitle}
              </span>
            </div>
            <div className="brand-different-description">
              <span> {props.detailData.differentDescription}</span>
            </div>


          </div>
          : ""}
        {props.detailData.brandbox && props.detailData.brandbox ? <><div className="brand-result">
          <span className="brand-result-text">
            <span class="banner-caption-arrow-sec">
              <span className="banner-caption-arrow"></span>
              <span className="banner-caption-arrow1"></span>
            </span>
            {props.detailData.brandbox ? props.detailData.brandResultText : null}
          </span>


          <div className="campaign-perf-info">
            {props.detailData.brandbox?.map((x) => (
              <>{x.brandBoxCount && x.brandBoxCount ?
                <div className="brand-box">
                  <div className="brand-box-row-1">
                    <div className="brand-box-logo">
                      <>
                        { !x.brandBoxIcon && !x.brandBoxHeart && !x.brandBoxImpression &&
                          <img src={x.brandBoxLogo} alt="" />
                        }
                        {x.brandBoxIcon &&
                          <i className="bi bi-chat" style={{fontSize:"24px", color:"#DF5D93", verticalAlign:"middle",lineHeight:"24px"}}></i>
                        }
                        {x.brandBoxHeart &&
                          <i className="bi bi-chat-heart" style={{fontSize:"24px", color:"#DF5D93", verticalAlign:"middle",lineHeight:"24px"}}></i>
                        }  
                        {x.brandBoxImpression &&
                          <i className="bi bi-eye" style={{fontSize:"24px", color:"#DF5D93", verticalAlign:"middle",lineHeight:"24px"}}></i>
                        }
                      </>
                    </div>
                    <div className="brand-box-name">
                      <span>{x.brandBoxName}</span>
                    </div>
                  </div>
                  <div className="count">{x.brandBoxCount}</div>
                </div>
                : ""}
              </>
            ))}
          </div>
        </div>
        </>
          : null}
        <div className="d-flex justify-content-center align-items-center">
          <span className="brand-result-text">
            <span class="banner-caption-arrow-sec">
              <span class="banner-caption-arrow"></span>
              <span class="banner-caption-arrow1"></span>
            </span>
            Campaign Posts
          </span>
        </div>

        <div className={props.detailData.brandPost && props.detailData.brandPost.length > 1 ? "brand-posts" : "brand-posts justify-content-center"}>
          {props.detailData.brandPost?.map((z) => (
            <div className="brand-posts-img">
              <a href={z.link} target="_blank">
                <img src={z.brandPostImg}></img>
              </a>
            </div>
          ))}
        </div>
        <div className="d-flex justify-content-center my-5">
          <a className="btn btn-black btn-wide" href="/case_studies">View all case studies</a>
        </div>
      </div>

    </>
  );
};

export default CaseStudiesDetailContainer;
