import React, { useEffect } from 'react'
import './Brand.css'

import SectionTitle from '../../section_title';
import { brandData } from '../../../data/data';
import Slider from "react-slick";
import lozad from 'lozad';

const Brands = () => {
    const { observe } = lozad("[data-use-lozad]", {
        loaded: el => {
            el.classList.add("lozad-fade");
        }
    });

    useEffect(() => {
        observe();
    }, [observe]);
    var settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        initialSlide: 0,
        responsive: [

            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,

                }
            },
            {
                breakpoint: 575,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,

                }
            },
        ]
    };
    return (
        <>
            <div className='homepage-brands'>
                <div className="triangle-arrow-sec"><span className="triangle-bg triangle2"></span><span className="triangle-bg triangle3"></span><span className="triangle-bg triangle4"></span><span className="triangle-bg triangle5"></span><span className="triangle-bg triangle6"></span><span className="triangle-bg triangle7"></span><span className="triangle-bg triangle8"></span><span className="triangle-bg triangle9"></span></div>
                <SectionTitle headingText="BRANDS WE HAVE WORKED WITH" />
                <div className="homepage-brand-images">
                    {brandData.map((c, index) => {
                        return (<>
                        <picture className='homepage-brands-logo' key={index}>
                            <source type="image/webp" srcSet={c.brandLogoWebp} loading='lazy' className='lozad img-fluid' data-use-lozad />
                            <source type="image/jpeg" srcSet={c.brandLogo} loading='lazy' className='lozad img-fluid' data-use-lozad />
                            <img src={c.brandLogo} alt="" loading='lazy' className='lozad img-fluid' data-use-lozad />
                        </picture></>

                        )
                    })}

                </div>
            </div>
        </>
    )
}

export default Brands