import React, { useEffect, useState } from 'react';
// import ReactPlayer from 'react-player';
// import { HomeBannerModalVideoData } from '../../../data/data';
import NewBannerMob from './NewBannerMob';

const NewBanner = (props) => {
    // const [playVideo, setPlayVideo] = useState(false);
    // const [videoEnded, setVideoEnded] = useState(false);
    // const [showElement, setShowElement] = useState(false);

    // const handleVideoEnded = () => {
    //     setVideoEnded(true);
    //     setPlayVideo(false);
    //     setShowElement(false);
    // };

    // const handleClickPlay = () => {
    //     setPlayVideo(true);
    //     setVideoEnded(false);
    //     setShowElement(true)

    // };

    // // const handleStop = () => {
    // //     setPlayVideo(false);
    // //     setVideoEnded(true);
    // // };

    // const handleClose = () => {
    //     setPlayVideo(false);
    //     setVideoEnded(false);
    //     setShowElement(false);
    // };

    // useEffect(() => {
    //     const timer = setTimeout(() => {
    //         setShowElement(true);
    //     }, 2000);

    //     return () => clearTimeout(timer);
    // }, []);

    return (
        // <section className={`homepage-banner-combined ${videoEnded ? 'video-ended-parent' : ''}`}>
        <section className={`homepage-banner-combined`}>
            {/* {showElement && (
                <section className={`homepage-video-banner eleContainer d-none d-md-flex ${videoEnded ? 'video-ended' : ''}`}>
                    <div className='homepage-video-sec'>

                        <span className='homepage-video-close' onClick={handleClose}>
                            <i className='bi bi-x-lg'></i>
                        </span>

                        <div class='ratio ratio-16x9'>
                            <div className='player-wrapper'>

                                <ReactPlayer
                                    id='homepage-video-wrapper'
                                    className='react-player'
                                    url={HomeBannerModalVideoData.videoURL}
                                    muted={true}
                                    controls={true}
                                    playing={()=> setplayVideo(!playVideo)}
                                    onEnded={handleVideoEnded}
                                    playsinline={true}
                                />

                            </div>
                        </div>
                    </div>
                </section>
            )} */}
            {/* <NewBannerMob showElement={showElement} videoEnded={videoEnded} handleClickPlay={handleClickPlay} /> */}
            <NewBannerMob />
        </section>
    );
};

export default NewBanner;