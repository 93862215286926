import React, { useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom';
import { blogsData } from "../../../data/data";
import DOMPurify from "dompurify";


const BlogsDetailContainer = (props) => {
    const addClass = document.querySelector('body');
    console.log(addClass)
    addClass.classList.add("page-blog-detail") 
    console.log("Detail", props.blogData)

    return (
        <section className='blog-sec brandBackground'>  <div className='container container-blogs '>
                <div className="banner-innerpage">
                    <img src={props.blogData.newsImage} alt={props.blogData.newsTitle} style={{"objectFit":"contain"}} />
                </div>
                <div className='blog-content-desc sec-common'>
                    <div className='container'>
                        <h1>{props.blogData.newsTitle}</h1>
                        <p>{props.blogData.newsDescription}</p>
                            <div dangerouslySetInnerHTML={{ __html: props.blogData.content }} />
                        <div className="d-flex justify-content-center my-5">
                            <a className="btn btn-black btn-wide" href="/blogs">View all blogs</a>
                        </div>

                    </div>

                </div>
            </div>
        </section>

    )
}

export default BlogsDetailContainer