// Team Member's Images
import lishitaJain from '../../../assets/images/TeamMember-img/lishita-jain.png';
import chandrayee from '../../../assets/images/TeamMember-img/chandrayee.png';

import Anshija from '../../../assets/images/TeamMember-img/anshija-seth.png';
import Shivomana from '../../../assets/images/TeamMember-img/shivomana-bandari.png';
import Shweta from '../../../assets/images/TeamMember-img/shweta-jain.jpg';

import Aman from '../../../assets/images/TeamMember-img/aman-banduni.png';
import Kapil from '../../../assets/images/TeamMember-img/kapil-singh-verma.png';
import Ravi from '../../../assets/images/TeamMember-img/ravi-kumar.png';
import Arnika from '../../../assets/images/TeamMember-img/arnika-garg.png';
import AmanSharma from '../../../assets/images/TeamMember-img/aman-sharma.png';
import Chitra from '../../../assets/images/TeamMember-img/chitra.png';
import Ritik from '../../../assets/images/TeamMember-img/ritik-kanojia.png';
import Imran from '../../../assets/images/TeamMember-img/imran-ansari.png';
import Shobhit from '../../../assets/images/TeamMember-img/shobhit-chauhan.png';
import Adhiraj from '../../../assets/images/TeamMember-img/adhiraj-nanda.png';


const TeamMembersData = [
  {
    id: "1",
    teamMemberImg: Kapil,
    teamMemberName: "Kapil Singh Verma",
    teamMemberDesignation: 'Senior Engineering Manager'
  },
  {
    id: "2",
    teamMemberImg: Arnika,
    teamMemberName: "Arnika Garg",
    teamMemberDesignation: 'Lead UX UI Designer'
  },
  {
    id: "3",
    teamMemberImg: Ravi,
    teamMemberName: "S Ravi Kumar",
    teamMemberDesignation: 'Senior Frontend Developer'
    
  },
  {
    id: "4",
    teamMemberImg: Aman,
    teamMemberName: "Aman Banduni",
    teamMemberDesignation: 'Fullstack Developer'
  },
  {
    id: "5",
    teamMemberImg: Chitra,
    teamMemberName: "Chitra",
    teamMemberDesignation: 'Developer'
  },
  {
    id: "6",
    teamMemberImg: AmanSharma,
    teamMemberName: "Aman Sharma",
    teamMemberDesignation: 'Developer'
  },
  {
    id: "7",
    teamMemberImg: Shobhit,
    teamMemberName: "Shobhit Chauhan",
    teamMemberDesignation: 'Developer-Intern'
  },
  {
    id: "8",
    teamMemberImg: Adhiraj,
    teamMemberName: "Adhiraj Nanda",
    teamMemberDesignation: 'Developer-Intern'
  },
  {
    id: "9",
    teamMemberImg: lishitaJain,
    teamMemberName: "Lishita Jain",
    teamMemberDesignation: 'Manager - Influencer Marketing'
  },
  {
    id: "10",
    teamMemberImg: chandrayee,
    teamMemberName: "Chandrayee Chattopadhyay",
    teamMemberDesignation: 'Associate Brand Manager'
  },
  {
    id: "11",
    teamMemberImg: Shivomana,
    teamMemberName: "Shivomana Bhandari",
    teamMemberDesignation: 'Executive- Influencer Marketing'
  },
  {
    id: "12",
    teamMemberImg: Anshija,
    teamMemberName: "Anshija Seth",
    teamMemberDesignation: 'Executive- Influencer Marketing'
  },
  {
    id: "13",
    teamMemberImg: Shweta,
    teamMemberName: "Shweta Jain",
    teamMemberDesignation: 'Executive- Influencer Marketing'
  },
  {
    id: "14",
    teamMemberImg: Ritik,
    teamMemberName: "Ritik Kanojia",
    teamMemberDesignation: 'Sr. Assistant- Operations'
  },
  {
    id: "15",
    teamMemberImg: Imran,
    teamMemberName: "Imran Ansari",
    teamMemberDesignation: 'Platform Operations Executive'
  }
];


export default TeamMembersData;
