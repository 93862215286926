import React from "react";
const Banner = () => {

  return (
    <>
      <div className="homeBanner homeBannerLatest inner">
        <div className="container">
          <div className="banner-content">
            <h1 className="banner-innerpage-heading">About Us</h1>
            <p className="banner-text">
              ClanConnect is a platform that allows you to plan and track all of your influencer marketing campaigns. We have the tools and data you need to make informed decisions. We are disrupting the marketing agency model by facilitating data-driven influencer marketing, making it easy for anyone to run a successful influencer marketing campaign.
            </p>
            <p>
              What separates us is our commitment to transparency in everything we do.</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Banner;
