import React from "react";
// import Highcharts from 'highcharts/modules/map'
// import HighchartsReact from "highcharts-react-official";

const MapChart = ({ options, highcharts }) => (
  <div>Hello</div> 
  // <HighchartsReact
  //   highcharts={highcharts}
  //   constructorType={"mapChart"}
  //   options={options}
  // />
);
export default MapChart;
