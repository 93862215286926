import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { NewsData } from "../../../data/data";
const NewsContainer = (props) => {
  const addClass = document.querySelector('body');
  addClass.classList.add("common-bg-page")
  return (
    <>
      <div class="homeBanner homeBannerLatest inner news">
        <div class="container">
          <div class="banner-content">
            <h1 class="banner-innerpage-heading">In News</h1>
          </div>
        </div>
      </div>
      <div className="News_Container">
        {NewsData.slice().reverse().map((data) => (
          <a className="news-card" href={data.postLink}> 
            <div className="news-card-image">
              <span href={data.postLink} target="_blank">
                <img src={data.postimage}></img>
              </span>
            </div>
            <div className="news-card-info">
              <span className="news-date">{data.date}</span>
              <span className="news-headline"><a href={data.postLink} target="_blank">{data.headLine}</a></span>{" "}
              <span className="news-link">
                <span class="banner-caption-arrow-sec">
                  <span class="banner-caption-arrow"></span>
                  <span class="banner-caption-arrow1"></span>
                </span>
                &ensp;&ensp;&ensp;&ensp; Read more at
                <span className="news-source">
                  &nbsp;
                  {data.newsSource}
                </span>
              </span>
            </div>
          </a>
        ))}
      </div>
    </>
  );
};

export default NewsContainer;
