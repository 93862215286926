import React, { useState, useEffect, useRef } from "react";
import Slider from "react-slick";
import FoundersData from "./FoundersData";

const Founders = (props) => {
  const [autoPlayOn, setAutoPlayOn] = useState(true);
  const sliderRef = useRef(null);
  const BiPause = `bi - pause - circle`;
  const BiPlay = `bi - play - circle`;
  const [indlFounderData, setindlFounderData] = useState(false)
  const [founderId, setFounderId] = useState([])
  const playPause = () => {
    setAutoPlayOn(!autoPlayOn);
    if (!autoPlayOn) {
      sliderRef.current.slickPlay();
    } else {
      sliderRef.current.slickPause();
    }
  };

  const settings = {
    dots: true,
    infinite: true,
    fade: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplayspeed: 10000,
    cssEase: "ease-in-out",
    pauseOnHover: false,
    arrows: false,
  };

  const handleFounderInfo = (id) => {
    console.log()
    
    const indlFounder = FoundersData.filter(founder => {
      if(founder.id === id) {
        setindlFounderData(!indlFounderData)
      }
      return founder.id === id
      
    })
   
    setFounderId(indlFounder)
  }
  const handleFounderClose = () => {
    setFounderId([])
    setindlFounderData(false)
  }
  console.log(founderId)

  return (

    <section className="sec-founders">
      <div className="containerWrap">
        <div class="triangle-arrow-sec">
          <span class="triangle-bg triangle3"></span><span class="triangle-bg triangle4"></span><span class="triangle-bg triangle5"></span><span class="triangle-bg triangle6"></span><span class="triangle-bg triangle7"></span><span class="triangle-bg triangle8"></span><span class="triangle-bg triangle9"></span>
        </div>
        <h1 className="Founders-Heading Founders-Row">Founders</h1>
        <section className="founder-sec">
          {founderId.map(founder => {
            return <div id={`founder-${founder.id}`} className={`${indlFounderData ? "indl-founder-info active" : "indl-founder-info"}`}>
              <div className="indl-founder-info-left">
                <img src={founder.founderImg} alt="" />
              </div>
              <div className="indl-founder-info-right">
                <h2 className="Founders-Name">{founder.founderName}</h2>
                <h3 className="Founders-Designation">
                  {founder.founderDesignation}
                </h3>
                <p>{founder.founderInfoPara1}</p>
                <p>{founder.founderInfoPara2}</p>
                <p>{founder.founderInfoPara3}</p>
              </div>
              <a href="javascript:void(0)" className="founder-close-btn" onClick={handleFounderClose}><i className="bi bi-x-lg"></i></a>
            </div>
          })}
          {FoundersData.map((props) => (
            <>
              <div className="founder-info d-none d-md-flex">
                <a href="javascript:void(0)" onClick={() => { handleFounderInfo(props.id) }} className={`${indlFounderData ? "founder-img active" : "founder-img"}`}>
                  <div className="Founders-Image-Background">
                    <img src={props.founderImg} alt="" />
                  </div>
                  <div className="founder-detail-desktop flex-column">
                    <h2 className={`${indlFounderData ? "Founders-Name hidden" : "Founders-Name"}`}>{props.founderName}</h2>
                    <h3 className={`${indlFounderData ? "Founders-Designation hidden" : "Founders-Designation"}`}>
                      {props.founderDesignation}
                    </h3>
                  </div>
                </a>

              </div>
              <div className="founder-info d-flex d-md-none">
                <div className={`${indlFounderData ? "founder-img active" : "founder-img"}`}>
                  <div className="Founders-Image-Background">
                    <img src={props.founderImgMob} alt="" />
                    <div className="founder-detail-mob d-none d-sm-flex flex-column d-md-none">
                      <h2 className="Founders-Name">{props.founderName}</h2>
                      <h3 className="Founders-Designation">
                        {props.founderDesignation}
                      </h3>
                    </div>
                  </div>
                  <div className="founder-info-detail">
                    <h2 className="Founders-Name d-block d-sm-none d-md-block">{props.founderName}</h2>
                    <h3 className="Founders-Designation d-block d-sm-none d-md-block">
                      {props.founderDesignation}
                    </h3>
                    <p>{props.founderInfoPara1}</p>
                    <p>{props.founderInfoPara2}</p>
                    <p>{props.founderInfoPara3}</p>
                  </div>
                </div>

              </div>
              {/* <div key={props.id}>

                <div className="Founders-Section  Founders-Row">
                  <div className="Founders-Content Founders-Row Founders-Col">
                    <div className="Founders-Image-Section Founders-Col">
                      <div className="Founders-Image-Background">
                         <div className="ribbon-1"></div>
                      <div className="circle-1"></div> 

                        <img
                          className="Founders-Image"
                          src={props.founderImg}
                          alt=""
                        />
                        {/* <div className="ribbon-2"></div>
                      <div className="circle-2"></div>
                      <div className="Founder-Interest1">
                        <img src={props.interest1} />
                      </div>
                      <div className="Founder-Interest2">
                        <img src={props.interest2} />
                      </div>
                      <div className="Founder-Interest3">
                        <img src={props.interest3} />
                      </div>
                      <div className="Founder-Interest4">
                        <img src={props.interest4} />
                      </div> 
                      </div>
                    </div>

                    <div className="Founders-Description Founders-Col">
                      <div className="Founder-Center">
                        <h2 className="Founders-Name">{props.founderName}</h2>
                        <h3 className="Founders-Designation">
                          {props.founderDesignation}
                        </h3>
                        <hr align="left"></hr>
                      </div>

                      <div className="Founder-Info">
                        <p>{props.founderInfoPara1}</p>
                        <p>{props.founderInfoPara2}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
            </>
          ))}


        </section>

        {/* 

        <Slider {...set+tings} ref={sliderRef}>
          {FoundersData.map((props) => (
            <div key={props.id}>
              <div className="Founders-Section  Founders-Row">
                <div className="Founders-Content Founders-Row Founders-Col">
                  <div className="Founders-Image-Section Founders-Col">
                    <div className="Founders-Image-Background">
                      {/* <div className="ribbon-1"></div>
                      <div className="circle-1"></div> */}

        {/* <img
          className="Founders-Image"
          src={props.founderImg}
          alt=""
        /> */}
        {/* <div className="ribbon-2"></div>
                      <div className="circle-2"></div>
                      <div className="Founder-Interest1">
                        <img src={props.interest1} />
                      </div>
                      <div className="Founder-Interest2">
                        <img src={props.interest2} />
                      </div>
                      <div className="Founder-Interest3">
                        <img src={props.interest3} />
                      </div>
                      <div className="Founder-Interest4">
                        <img src={props.interest4} />
                      </div> 
                    </div>
                  </div>

                  <div className="Founders-Description Founders-Col">
                    <div className="Founder-Center">
                      <h2 className="Founders-Name">{props.founderName}</h2>
                      <h3 className="Founders-Designation">
                        {props.founderDesignation}
                      </h3>
                      <hr align="left"></hr>
                    </div>

                    <div className="Founder-Info">
                      <p>{props.founderInfoPara1}</p>
                      <p>{props.founderInfoPara2}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
        */}
        {/* <div className="playPause">
          <button onClick={playPause}>
            {autoPlayOn ? (
              <i className="bi bi-pause-circle"></i>
            ) : (
              <i className="bi bi-play-circle"></i>
            )}
          </button>
        </div> */}
      </div>
    </section>
  );
};
export default Founders;
