import React, { useState, useEffect, useRef } from "react";

import TeamMembersData from "./TeamMembersData";
import Slider from "react-slick";
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';

function TeamMembersComponent() {
  let sliderRef = React.useRef();
  let [memberDetail, setMemberDetail] = useState(false)
  let [teamMemberName, setTeamMemberName] = useState("")
  let [memberInfo, setMemberInfo] = useState([])
  const violationRef = useRef(null);
  let [memberDetailId, setMemberDetailId] = useState(memberInfo)
  let [memberActive, setmemberActive] = useState(false)
  const settings = {
    infinite: true,
    dots: false,
    slidesToShow: 4,
    arrows: false,
    lazyLoad: true,

    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  const HandleTeamMemberDetail = (id) => {
    const newItem = TeamMembersData.filter((newVal) => {
      return newVal.id === id;
    });
    // const indlMember = document.querySelectorAll("TeamMember-info")
    // indlMember.forEach((member) => {
    //   console.log(member)
    //   return member.id === id
    // })
    // console.log(indlMember)
    setMemberInfo(newItem)
    setMemberDetail(!memberDetail)
    violationRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
    setMemberDetailId(newItem[0].id)
    console.log("memberDetailId", memberDetailId)
    console.log("newItem", newItem[0].id)

  }

  const handleClearTeamInfo = () => {
    setMemberDetail(false)
    setMemberInfo([])
  }


  useEffect(() => {
  }, [])
  return (
    <section className="team-member-slider">
      <h2 className="text-center">Business Drivers</h2>
      <Row className="team-member-tab-sec">
        {TeamMembersData.slice(8).map((props) => (
          <a href="javascript:void(0)" className={`${memberActive ? "TeamMember-info" : "TeamMember-info"}`}
            onClick={() => HandleTeamMemberDetail(props.id)} key={props.id}>
            <div className="TeamMember-info-img-indl">

              <img
                src={props.teamMemberImg}
                className="TeamMember-Img"
                alt={props.teamMemberName}
              />
              {/* <img
                src={props.teamMemberImgHover ? props.teamMemberImgHover : props.teamMemberImg}
                className="TeamMember-HoverImg"
                alt={props.teamMemberName}
              /> */}
            </div>  
            <div className="TeamMember-info-indl">
              <span className="TeamMember-info-indl-name">{props.teamMemberName}</span>
              <span className="TeamMember-info-indl-des">{props.teamMemberDesignation}</span>
            </div>
          </a>
        ))}
      </Row>
      <h2 className="text-center">Our Geeks</h2>
      <Row className="team-member-tab-sec">
        {TeamMembersData.slice(0, 8).map((props) => (
          <a href="javascript:void(0)" onClick={() => HandleTeamMemberDetail(props.id)} className="TeamMember-info" key={props.id}>

            <div className="TeamMember-info-img-indl">

              <img
                src={props.teamMemberImg}
                className="TeamMember-Img"
                alt={props.teamMemberName}
              />
              {/* <img
                src={props.teamMemberImgHover ? props.teamMemberImgHover : props.teamMemberImg}
                className="TeamMember-HoverImg"
                alt={props.teamMemberName}
              /> */}
            </div>
            <div className="TeamMember-info-indl">

              <span className="TeamMember-info-indl-name">{props.teamMemberName}</span>
              <span className="TeamMember-info-indl-des">{props.teamMemberDesignation}</span>
            </div>

          </a>
        ))}
      </Row>
      {/* <Tab.Container id="left-tabs-example" defaultActiveKey="Business Team">
        <Row className="team-member-tab-sec">
          <Col sm={3}>
            <div className="team-member-tab-container">
              <Nav className="flex-column">
                <Nav.Item>
                  <Nav.Link onClick={handleClearTeamInfo} eventKey="Business Team">Business Team</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link onClick={handleClearTeamInfo} eventKey="Development Team">Development Team</Nav.Link>
                </Nav.Item>
              </Nav>
            </div>
          </Col>

          <Col sm={9}>
            <Tab.Content className="team-member-tab-content">
              <Tab.Pane eventKey="Business Team">
                {TeamMembersData.slice(7).map((props) => (
                  <a href="javascript:void(0)" className={`${memberActive ? "TeamMember-info asdf" : "TeamMember-info"}`}
                    onClick={() => HandleTeamMemberDetail(props.id)} key={props.id}>
                    <div className="TeamMember-info-indl">
                      <span className="TeamMember-info-indl-name">{props.teamMemberName}</span>
                      <span className="TeamMember-info-indl-des">{props.teamMemberDesignation}</span>
                    </div>
                    <img
                      src={props.teamMemberImg}
                      className="TeamMember-Img"
                      alt={props.teamMemberName}
                    />
                  </a>
                ))}
              </Tab.Pane>
              <Tab.Pane eventKey="Development Team">
                {TeamMembersData.slice(0, 7).map((props) => (
                  <a href="javascript:void(0)" onClick={() => HandleTeamMemberDetail(props.id)} className="TeamMember-info" key={props.id}>
                    <span className="">{props.teamMemberName}</span>
                    <img
                      src={props.teamMemberImg}
                      className="TeamMember-Img"
                      alt={props.teamMemberName}
                    />
                  </a>
                ))}
              </Tab.Pane>
            </Tab.Content>


            {memberInfo.map((mem) => {
              return <div ref={violationRef} key={mem.id} className={`${memberDetail ? "team-member-info-container active" : "team-member-info-container"}`}>

                <strong>{mem.teamMemberName}</strong> - <span>{mem.teamMemberDesignation}</span>
              </div>
            })}


          </Col>
        </Row>
      </Tab.Container> */}

      <div>
        <div className="containerWrap">
          {/* <Slider {...settings} ref={sliderRef}>

          </Slider> */}
          {/* <div className="team-member-nav d-flex justify-content-end">
            <a onClick={() => sliderRef?.current?.slickPrev()}>
              <i className="bi bi-arrow-left-circle"></i>
            </a>
            <a onClick={() => sliderRef?.current?.slickNext()}>
              <i className="bi bi-arrow-right-circle"></i>
            </a>
          </div> */}
        </div>
      </div>
    </section>
  );
}

export default TeamMembersComponent;
