import React, { useEffect } from "react";
import BlogsSection from "./BlogsSection";


const BlogsContainer = () => {
  const addClass = document.querySelector('body');
  console.log(addClass)
  addClass.classList.add("common-bg-page")
  // addClass.classList.add("asdfa")
  // useEffect(()=>{
    
  //   const addClass = document.getElementsByTagName('body');
  //   addClass.classList.add("asdfa")
  // }, [])
  return (
    <>
      <div className="homeBanner homeBannerLatest inner news">
        <div className="container">
          <div className="banner-content">
            <h1 className="banner-innerpage-heading">Blogs</h1>
          </div>
        </div>
      </div>
      <BlogsSection />
    </>
  );
};

export default BlogsContainer;
